import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'Login',
    component: resolve => require(['../views/login.vue'], resolve)
  },
  {
    path: '/index',
    name: 'Home',
    component: resolve => require(['../views/home.vue'], resolve)
  },
  {
    path: '/analysis',
    name: 'Analysis',
    component: resolve => require(['../views/analysis.vue'], resolve)
  },
  {
    path: '/error',
    name: 'Error',
    component: resolve => require(['../views/error.vue'], resolve)
  },
  {
    path: '/loading',
    name: 'Loading',
    component: resolve => require(['../views/loading/index.vue'], resolve)
  },
  {
    path: '/style',
    name: 'style',
    component: resolve => require(['../views/style.vue'], resolve)
  }
]

const router = new VueRouter({
  routes
})

export default router
