import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    guanList: [],
    shiList: [],
    userName: '',
    coronalUrls: [],
    sagittalUrls: []
  },
  getters: {
  },
  mutations: {
    SET_GUAN_LIST (state, value) {
      state.guanList = value
      console.log(value, 'guanList')
    },
    SET_SHI_LIST (state, value) {
      state.shiList = value
      console.log(value, 'shiList')
    },
    SET_USER_NAME (state, value) {
      state.userName = value
    },
    SET_coronalUrls (state, value) {
      state.coronalUrls = value
    },
    SET_sagittalUrls (state, value) {
      state.sagittalUrls = value
    }
  },
  modules: {
  }
})
